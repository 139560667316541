const zh_CN = {
  title: '告警引擎',
  instance: '引擎实例',
  cluster: '引擎集群',
  datasource: '数据源',
  modify_datasource: '修改数据源',
  clock: '上次心跳时间',
  unauthorized: '您没有权限查看',
};
export default zh_CN;
