const zh_HK = {
  placeholder: '請選擇時間',
  start: '開始時間',
  end: '結束時間',
  history: '最近使用的時間範圍',
  quickSearchPlaceholder: '搜索快捷選項',
  ok: '確定',
  invalid: '時間格式錯誤',
  spans: {
    second: '秒',
    minute: '分鐘',
    hour: '小時',
    day: '天',
    week: '週',
    month: '月',
    year: '年',
  },
  rangeOptions: {
    'Last 1 minute': '最近 1 分鐘',
    'Last 2 minutes': '最近 2 分鐘',
    'Last 3 minutes': '最近 3 分鐘',
    'Last 5 minutes': '最近 5 分鐘',
    'Last 15 minutes': '最近 15 分鐘',
    'Last 30 minutes': '最近 30 分鐘',
    'Last 1 hour': '最近 1 小时',
    'Last 3 hours': '最近 3 小时',
    'Last 6 hours': '最近 6 小时',
    'Last 12 hours': '最近 12 小时',
    'Last 24 hours': '最近 24 小时',
    'Last 2 days': '最近 2 天',
    'Last 7 days': '最近 7 天',
    'Last 30 days': '最近 30 天',
    'Last 90 days': '最近 90 天',
    Yesterday: '昨天',
    'Day before yesterday': '前天',
    'This day last week': '上週今天',
    'Previous week': '上週',
    'Previous month': '上個月',
    Today: '今天',
    'Today so far': '今天到現在',
    'This week': '本週',
    'This week so far': '本周到現在',
    'This month': '本月',
    'This month so far': '本月到現在',
  },
  last: '最近',
  next: '接下來',
};
export default zh_HK;
