const en_US = {
  仪表盘: 'Dashboard',
  监控仪表盘: 'Dashboard',
  内置仪表盘: 'Built-in',
  时序指标: 'Metrics',
  即时查询: 'Explorer',
  快捷视图: 'Quick View',
  记录规则: 'Record Rule',
  日志分析: 'Log Analysis',
  链路追踪: 'Tracing',
  拓扑分析: 'Dependencies',
  基础设施: 'Infrastructure',
  监控机器: 'Host',
  告警管理: 'Monitors',
  告警规则: 'Alert Rule',
  内置规则: 'Built-in',
  屏蔽规则: 'Mutes',
  订阅规则: 'Subscriptions',
  活跃告警: 'Active',
  历史告警: 'Historical',
  告警自愈: 'Self-healing',
  自愈脚本: 'Self-healing',
  自愈配置: 'Ibex Settings',
  执行历史: 'Execution History',
  人员组织: 'Personnel Orgs',
  用户管理: 'Users',
  团队管理: 'Teams',
  业务组管理: 'Business Groups',
  权限管理: 'Permission',
  系统配置: 'Configuration',
  系统版本: 'Version',
  告警引擎: 'Alert Engines',
  数据源: 'Data Sources',
  单点登录: 'SSO',
  通知模板: 'Notification Template',
  通知设置: 'Notification Settings',
};
export default en_US;
