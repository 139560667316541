const zh_CN = {
  title: '即时查询',
  add_btn: '新增一个查询面板',
  query_btn: '查询',
  log: {
    search_placeholder: '搜索字段',
    available: '可选字段',
    selected: '已选字段',
    interval: '间隔',
  },
};
export default zh_CN;
