const zh_CN = {
  title: '内置仪表盘',
  cate: '分类',
  name: '仪表盘名称',
  tags: '标签',
  json_label: '仪表盘 JSON',
  json_msg: 'JSON 格式错误，请检查',
  detail_no_result: '查询的内置仪表盘不存在',
};
export default zh_CN;
