const zh_CN = {
  placeholder: '请选择时间',
  start: '开始时间',
  end: '结束时间',
  history: '最近使用的时间范围',
  quickSearchPlaceholder: '搜索快捷选项',
  ok: '确定',
  invalid: '时间格式错误',
  spans: {
    second: '秒',
    minute: '分钟',
    hour: '小时',
    day: '天',
    week: '周',
    month: '月',
    year: '年',
  },
  rangeOptions: {
    'Last 1 minute': '最近 1 分钟',
    'Last 2 minutes': '最近 2 分钟',
    'Last 3 minutes': '最近 3 分钟',
    'Last 5 minutes': '最近 5 分钟',
    'Last 15 minutes': '最近 15 分钟',
    'Last 30 minutes': '最近 30 分钟',
    'Last 1 hour': '最近 1 小时',
    'Last 3 hours': '最近 3 小时',
    'Last 6 hours': '最近 6 小时',
    'Last 12 hours': '最近 12 小时',
    'Last 24 hours': '最近 24 小时',
    'Last 2 days': '最近 2 天',
    'Last 7 days': '最近 7 天',
    'Last 30 days': '最近 30 天',
    'Last 90 days': '最近 90 天',
    Yesterday: '昨天',
    'Day before yesterday': '前天',
    'This day last week': '上周今天',
    'Previous week': '上周',
    'Previous month': '上个月',
    Today: '今天',
    'Today so far': '今天到现在',
    'This week': '本周',
    'This week so far': '本周到现在',
    'This month': '本月',
    'This month so far': '本月到现在',
  },
  last: '最近',
  next: '接下来',
};
export default zh_CN;
