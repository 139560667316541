const en_US = {
  title: 'Permissions',
  roles: 'Roles',
  role_add: 'Add Role',
  edit_title: {
    post: 'Add Role',
    put: 'Edit Role',
  },
};
export default en_US;
