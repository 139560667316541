const zh_CN = {
  title: '权限管理',
  roles: '角色列表',
  role_add: '添加角色',
  edit_title: {
    post: '添加角色',
    put: '编辑角色',
  },
};
export default zh_CN;
